import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ArrowLeft, EvaluaitLogoPayment, StripeIcon } from "src/assets/icons";
import { useAppSelector } from "src/store/hooks";
import { AddPaymentForm } from "./AddPaymentForm";
import classes from "./addPayment.module.scss";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PAYMENT_API_KEY as string,
);

const AddPayment: React.FC = () => {
  const clientSecret = useAppSelector((state) => state.setting.client_secret);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/settings?tab=payment_methods");
  };

  return (
    <div className={classes.container}>
      <div className={classes.sectionWrapper}>
        <div className={classes.leftSection}>
          <div className={classes.topSection}>
            <EvaluaitLogoPayment className={classes.logo} />
            <div className={classes.info}>
              Interviews simplified for your next hiring process.
            </div>
            <div className={classes.returnText}>
              <ArrowLeft onClick={handleNavigate} cursor={"pointer"} />
              <div>Return to Evaluait</div>
            </div>
          </div>
          <div className={classes.bottomSection}>
            <span className={classes.poweredByStripe}>
              Powered by{" "}
              <Link
                to="https://stripe.com/"
                target="_blank"
                className={classes.link}
              >
                <StripeIcon />
              </Link>
            </span>
            <span className={classes.divider} />
            <Link
              to="https://stripe.com/billing"
              target="_blank"
              className={classes.link}
            >
              Learn more about Stripe Billing
            </Link>
            <span className={classes.divider} />
            <Link
              to="https://stripe.com/privacy"
              target="_blank"
              className={classes.link}
            >
              Privacy
            </Link>
          </div>
        </div>
        <div className={classes.rightSection}>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <AddPaymentForm />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default AddPayment;
