import { createAsyncThunk } from "@reduxjs/toolkit";
import { IntegrationService } from "src/api/IntegrationService";
import { notify } from "src/components/Toastify/Toastify";
import { IIntegration } from "src/modules/dashboard/Integration/interface/integration.type";
import { IResponse } from "src/utils/common.type";

const integrationService = new IntegrationService();

interface IPayload<T> {
  data: T;
  type: "connect" | "sync";
  cb?: (response: IResponse<T>) => void;
}

export const connectGreenhouse = createAsyncThunk(
  "integration/connectGreenhouse",
  async (payload: IPayload<IIntegration>) => {
    const { data, cb, type } = payload;

    try {
      const resp = await integrationService.connectGreenhouse(
        data as IIntegration,
        type,
      );
      if (resp.status) {
        notify({
          message: resp.data?.message,
          autoCloseTime: 3000,
        });
      }
      if (cb) {
        cb(resp);
      }
      return resp || payload;
    } catch (err) {
      const { error } = err as IResponse<IIntegration>;
      notify({
        message: error?.message,
        autoCloseTime: 3000,
        type: "error",
      });
      throw error;
    }
  },
);

export const disconnectGreenhouse = createAsyncThunk(
  "integration/disconnectGreenhouse",
  async (cb?: () => void) => {
    try {
      const resp = await integrationService.disconnectGreenhouse();
      if (resp) {
        notify({
          message: resp.data?.message,
          autoCloseTime: 3000,
        });
      }
      if (cb) {
        cb();
      }
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IIntegration>;
      if (cb) {
        cb();
      }
      throw error;
    }
  },
);
