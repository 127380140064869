import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import groupReducer from "./group";
import integrationReducer from "./integration";
import interviewReducer from "./interview";
import externalReducer from "./media";
import personReducer from "./person";
import settingReducer from "./setting";
import userReducer from "./user";

export const reducers = {
  auth: authReducer,
  interview: interviewReducer,
  integration: integrationReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  media: externalReducer,
  person: personReducer,
  group: groupReducer,
  setting: settingReducer,
};
