import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Button from "../Button";
import classes from "./stripeForm.module.scss";

interface Props {
  onSuccess: (stripe_id: string) => void;
  submitText: string;
  onCancel?: () => void;
  cancelBtnText?: string;
  hideCancelButton?: boolean;
  loading: boolean;
  disabled: boolean;
}

const StripeForm: React.FC<Props> = ({
  onSuccess,
  submitText,
  onCancel,
  hideCancelButton = true,
  cancelBtnText,
  loading = false,
  disabled = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setPaymentLoading(true);
    e.preventDefault();

    if (elements == null || stripe == null) {
      setPaymentLoading(false);
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setPaymentLoading(false);
      return;
    }
    const resp = await stripe.confirmSetup({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });
    if (resp?.setupIntent?.payment_method) {
      onSuccess(resp?.setupIntent?.payment_method as string);
    }
    setPaymentLoading(false);
  };
  return (
    <div className={classes.formWrapper}>
      <form
        className={classes.paymentForm}
        onSubmit={handleSubmit}
        data-testid="stripe_payment_form_wrapper"
      >
        {/* <div>APPLE</div>
        <div className={classes.divider}>
          <div className={classes.wordWithLine}>Or pay another way</div>
        </div>
        <Input
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        /> */}
        <PaymentElement options={{ terms: { card: "never" } }} />

        <Button
          type="submit"
          className={classes.btn}
          disabled={
            !stripe || !elements || paymentLoading || loading || disabled
          }
        >
          {submitText}
        </Button>
        {!hideCancelButton && (
          <Button
            className={classes.cancelBtn}
            type="button"
            variant="outline"
            disabled={paymentLoading || loading || disabled}
            onClick={onCancel}
          >
            {cancelBtnText || "Cancel"}
          </Button>
        )}
      </form>
    </div>
  );
};

export default StripeForm;
