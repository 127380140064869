import React, { useState } from "react";
import { useNavigate } from "react-router";
import BreadCrumb from "src/components/BreadCrumb";
import { StripeForm } from "src/components/StripeForm";
import { addPaymentMethod } from "src/store/actions";
import { useStoreActions } from "src/store/hooks";
import classes from "./addPaymentForm.module.scss";

const breadCrumbs = [
  { label: "Billing", redirectLink: "/settings" },
  { label: "Payment method", redirectLink: "" },
];

const AddPaymentForm: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const action = useStoreActions({ addPaymentMethod });
  const handleAddMethod = (stripe_id: string) => {
    setLoading(true);
    action.addPaymentMethod({
      data: { stripe_id: stripe_id },
      cb: () => navigate("/settings?tab=payment_methods&add-payment=true"),
    });
    setLoading(false);
  };
  return (
    <div className={classes.paymentFormWrapper}>
      <div className={classes.body}>
        <div className={classes.breadCrumbWrapper}>
          <BreadCrumb data={breadCrumbs} />
        </div>
        <div className={classes.title}>Add payment method</div>
        <StripeForm
          loading={loading}
          disabled={loading}
          onSuccess={handleAddMethod}
          onCancel={() => {
            navigate("/settings?tab=payment_methods");
          }}
          submitText="Add"
          cancelBtnText="Go Back"
          hideCancelButton={false}
        />
      </div>
    </div>
  );
};

export default AddPaymentForm;
