import cn from "classnames";
import React from "react";
import { TypeOptions } from "react-toastify";
import { AlertIcon, SuccessIcon } from "src/assets/icons";
import classes from "./Toast.module.scss";

interface Props {
  message: string;
  type: TypeOptions;
}
const ToastBody: React.FC<Props> = ({ message, type }) => {
  const getToastTitle = () => {
    switch (type) {
      case "error":
        return "Error!";
      default:
        return "Success!";
    }
  };
  const getToastIcon = () => {
    switch (type) {
      case "error":
        return <AlertIcon />;
      default:
        return <SuccessIcon />;
    }
  };

  return (
    <div className={classes.toastWrapper}>
      <div
        className={cn(classes.iconWrapper, {
          [classes.success]: type === "success",
          [classes.error]: type === "error",
        })}
      >
        {getToastIcon()}
      </div>
      <div className={classes.messageBody}>
        <div className={classes.title}>{getToastTitle()}</div>
        <div className={classes.message}>{message}</div>
      </div>
    </div>
  );
};

export default ToastBody;
