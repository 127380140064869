import cn from "classnames";
import { ToastPosition, TypeOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./Toast.module.scss";
import ToastBody from "./ToastBody";
interface Props {
  message?: string;
  position?: ToastPosition;
  type?: TypeOptions;
  autoCloseTime?: number;
}
export const notify = ({
  message = "",
  position = "bottom-center",
  type = "success",
  autoCloseTime = 2000,
}: Props) => {
  toast(<ToastBody message={message} type={type} />, {
    position: position,
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeButton: false,
    rtl: false,
    bodyClassName: classes.toastBody,
    className: cn(classes.toast, {
      [classes.success]: type === "success",
      [classes.error]: type === "error",
      [classes.bottom]: position === "bottom-center",
    }),
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: type,
    icon: false,
  });
};
