import { createSlice } from "@reduxjs/toolkit";
import {
  BillingInvoice,
  ICardDetails,
  IPlans,
} from "src/modules/dashboard/Settings/interface/settings.type";
import { IPagination } from "src/utils/common.type";
import { initialMeta } from "src/utils/helper";
import {
  addPaymentMethod,
  cancelSubscription,
  createStripeIntent,
  getHistory,
  getPaymentMethod,
  getPlans,
  letsChat,
  makeDefault,
  removePaymentMethod,
  subscribePlan,
} from "../actions";
export interface ISettingSlice {
  planList: IPlans[] | null;
  listLoading: boolean;
  client_secret: string;
  stripeLoader: boolean;
  transactionLoader: boolean;
  paymentMethods: ICardDetails[];
  message: string;
  loading: boolean;
  error: string;
  billingHistory: BillingInvoice[];
  billingMeta: IPagination;
}

const initialState: ISettingSlice = {
  planList: null,
  listLoading: false,
  client_secret: "",
  stripeLoader: false,
  paymentMethods: [],
  transactionLoader: false,
  loading: false,
  error: "",
  message: "",
  billingHistory: [],
  billingMeta: initialMeta,
};

export const SettingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //plans
    builder.addCase(getPlans.pending, (state) => {
      state.listLoading = true;
    });
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.listLoading = false;
      state.planList = action.payload;
    });
    builder.addCase(getPlans.rejected, (state, action) => {
      state.listLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //subscribe-plan
    builder.addCase(subscribePlan.pending, (state) => {
      state.transactionLoader = true;
    });
    builder.addCase(subscribePlan.fulfilled, (state) => {
      state.transactionLoader = false;
    });
    builder.addCase(subscribePlan.rejected, (state, action) => {
      state.transactionLoader = false;
      state.error = action.error.message || "Something went wrong";
    });
    // get billing history
    builder.addCase(getHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.billingHistory = action.payload.data;
    });
    builder.addCase(getHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //stripe-intent
    builder.addCase(createStripeIntent.pending, (state) => {
      state.stripeLoader = true;
    });
    builder.addCase(createStripeIntent.fulfilled, (state, action) => {
      state.stripeLoader = false;
      state.client_secret = action.payload;
    });
    builder.addCase(createStripeIntent.rejected, (state, action) => {
      state.stripeLoader = false;
      state.error = action.error.message || "Something went wrong";
    });
    //get-payment
    builder.addCase(getPaymentMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethods = action.payload;
    });
    builder.addCase(getPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //add-payment-method
    builder.addCase(addPaymentMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethods = action.payload;
    });
    builder.addCase(addPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //remove-payment-method
    builder.addCase(removePaymentMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removePaymentMethod.fulfilled, (state, action) => {
      const updatedMethods = state.paymentMethods.filter(
        (method) => method.id !== action.payload,
      );
      state.loading = false;
      state.paymentMethods = updatedMethods;
    });
    builder.addCase(removePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //make-default
    builder.addCase(makeDefault.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(makeDefault.fulfilled, (state, action) => {
      const newDefault = state.paymentMethods.map((method) => {
        if (method.id === action.payload) {
          return { ...method, is_default: 1 };
        } else {
          return { ...method, is_default: 0 };
        }
      });

      state.loading = false;
      state.paymentMethods = newDefault;
    });
    builder.addCase(makeDefault.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //cancel-subscription
    builder.addCase(cancelSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(cancelSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //let's-chat
    builder.addCase(letsChat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(letsChat.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(letsChat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export default SettingSlice.reducer;
