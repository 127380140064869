import { useMemo } from "react";
import Button from "src/components/Button";
import NavLink from "src/components/Link/Link";
import { useUpgradeUser } from "src/modules/UserProfile/hooks/useUpgradeUser";
import { useAppSelector, useStoreActions } from "src/store/hooks";
import { toggleSideBar } from "src/store/reducers/media";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./SidebarProfile.module.scss";

interface Props {
  email: string;
  name: string;
  avatar?: string;
}
const SidebarProfile: React.FC<Props> = ({ email, name, avatar }) => {
  const { width = 0 } = useWindowSize();
  const { handleUpgrade, upgradeLoading } = useUpgradeUser();
  const actions = useStoreActions({ toggleSideBar });
  const { setting, usage } = useAppSelector((state) => state.user);
  const { sideBar } = useAppSelector((state) => state.media);

  const handleSideBar = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (width < 781 && sideBar) actions.toggleSideBar(false);
    else return;
  };

  const getPlanName = () => {
    const interviewLimit = setting?.max_interviews || 0;
    if (interviewLimit > 25 && interviewLimit <= 100) {
      return "Premium Plan";
    }
    if (interviewLimit > 100) {
      return "Enterprise Plan";
    }
    return "Free Plan";
  };

  const interviewLeft = useMemo(
    () => (setting?.max_interviews || 0) - (usage?.interviews || 0),
    [usage?.interviews, setting?.max_interviews],
  );

  return (
    <div className={classes.container}>
      <div className={classes.progressTracker}>
        <div className={classes.badge}>{getPlanName()}</div>
        <div className={classes.count}>
          {interviewLeft < 0 ? 0 : interviewLeft}/{setting?.max_interviews}
          <span className={classes.message}> interviews remaining</span>
        </div>
        <Button
          variant="outline"
          className={classes.upgradeBtn}
          onClick={() => handleUpgrade()}
          disabled={upgradeLoading}
        >
          Upgrade Now
        </Button>
      </div>
      <div className={classes.lowerWrapper}>
        <div className={classes.iconWrapper}>
          {avatar ? (
            <img src={avatar} alt="user" />
          ) : (
            <div className={classes.logo}>
              {name?.substring(0, 1).toLocaleUpperCase()}
            </div>
          )}
        </div>
        <NavLink
          className={classes.infoWrapper}
          to="/profile"
          onClick={handleSideBar}
        >
          <div className={classes.userName}>{name}</div>
          <div className={classes.userMail}>{email}</div>
        </NavLink>
      </div>
    </div>
  );
};

export default SidebarProfile;
