import { IChatFormProps } from "../modules/dashboard/Settings/interface/settings.type";
import { Api } from "./Api";

export class SettingsService extends Api<
  IChatFormProps | { stripe_id: string }
> {
  constructor() {
    super("/billing");
  }

  async getPlans() {
    return this.get("plans");
  }

  async getHistory() {
    return this.get("history");
  }

  async subscribePlan(planId: number) {
    return this.post({}, `plans/${planId}/subscribe`);
  }

  async createStripeIntent() {
    return this.post({}, "payment-method/intent");
  }
  async addPaymentMethod(data: { stripe_id: string }) {
    return this.post(data, "payment-method");
  }
  async getPaymentMethod() {
    return this.get("payment-method");
  }
  async removePaymentMethod(id: number) {
    return this.delete(`payment-method/${id}`);
  }
  async cancelSubscription() {
    return this.post({}, "plans/cancel");
  }
  async letsChat(data: IChatFormProps) {
    return this.post(data, "plans/enterprise");
  }
  async makeDefault(id: number) {
    return this.post({}, `payment-method/${id}/default`);
  }
}
