import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { EvaluaitLogo } from "src/assets/icons";
import NavLink from "src/components/Link/Link";
import { useAppSelector, useStoreActions } from "src/store/hooks";
import { toggleSideBar } from "src/store/reducers/media";
import { useWindowSize } from "src/utils/useWindowSize";
import SidebarProfile from "./Profile/SidebarProfile";
import classes from "./sideNavigation.module.scss";

type Props = {
  sidebarElements: {
    title: string;
    route: string;
    icon: React.ReactNode;
    tabSelectedIcon?: React.ReactNode;
    isOpen?: boolean;
    matchingRoute?: string;
  }[];
};

const SideNavigation: React.FC<Props> = ({ sidebarElements }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { sideBar } = useAppSelector((state) => state.media);
  const actions = useStoreActions({ toggleSideBar });
  const { width = 0 } = useWindowSize();
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.user);

  const sideNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const activeTabIndex = sidebarElements.findIndex((sidebar) => {
      if (sidebar?.matchingRoute)
        return (
          pathname.includes(sidebar.route) ||
          pathname.includes(sidebar.matchingRoute)
        );
      return pathname.includes(sidebar.route);
    });
    setActiveIndex(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (sideBar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [sideBar]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sideNavRef.current &&
        !sideNavRef.current.contains(event.target as Node)
      ) {
        actions.toggleSideBar();
      }
    }

    if (sideBar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideBar]);

  const handleItemClick = () => {
    if (width < 781) actions.toggleSideBar();
  };

  return (
    <div className={classes.wrapper}>
      {width < 781 && sideBar && <div className={classes.backDrop} />}

      {width < 781 && !sideBar ? (
        <></>
      ) : (
        <div
          ref={sideNavRef}
          className={cn(classes.container, {
            [classes.open]: sideBar && width < 781,
            [classes.close]: !sideBar && width < 781,
          })}
        >
          <div className={classes.logoWrapper}>
            <EvaluaitLogo />
          </div>
          <div className={classes.sidebarWrapper}>
            <div className={classes.upperContainer}>
              <div className={classes.sideNavWrapper}>
                {sidebarElements.map((sidebar, index) => {
                  const isSelected = index === activeIndex;
                  return (
                    <NavLink
                      key={index}
                      to={sidebar.route}
                      className={cn(classes.navItemWrapper, {
                        [classes.active]: isSelected,
                      })}
                      onClick={handleItemClick}
                    >
                      <div className={classes.iconWrapper}>
                        {isSelected && sidebar?.tabSelectedIcon
                          ? sidebar?.tabSelectedIcon
                          : sidebar.icon}
                      </div>
                      <div
                        className={cn(classes.title, {
                          [classes.active]: isSelected,
                        })}
                      >
                        {sidebar.title}
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
            <SidebarProfile
              name={user?.name || ""}
              email={user?.email || ""}
              avatar={user?.avatar || ""}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNavigation;
