import { useNavigate } from "react-router";
import { useAppSelector } from "src/store/hooks";

export const useUpgradeUser = () => {
  const navigate = useNavigate();
  const { loading, upgradeLoading, error } = useAppSelector(
    (state) => state.user,
  );

  const handleUpgrade = async () => {
    navigate("/settings?tab=pricing");
  };

  return {
    handleUpgrade,
    loading,
    upgradeLoading,
    error,
  };
};
