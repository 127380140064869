import cn from "classnames";
import React, { useEffect, useMemo } from "react";
import { Navigate, matchPath, useLocation, useNavigate } from "react-router";
import Loader from "src/components/Loader";
import { sidebarMenu } from "src/constants/constants";
import { getSettings, getUser } from "src/store/actions";
import { useAppSelector, useStoreActions } from "src/store/hooks";
import { isLoggedIn } from "src/utils/helper";
import SideNavigation from "./components/SideNavigation";
import classes from "./privateLayout.module.scss";

interface Props {
  children: React.ReactNode;
}
const hideSideBarPaths = ["/create-company", "/upgrade-plan", "/add-payment"];
const PrivateLayout: React.FC<Props> = ({ children }) => {
  const isAuthenticated = isLoggedIn();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const actions = useStoreActions({ getUser, getSettings });
  const { user, loading } = useAppSelector((state) => state.user);
  const hideSideBar =
    hideSideBarPaths.includes(pathname) ||
    matchPath({ path: "/interview/preview/:id" }, pathname);

  const getUserData = async () => {
    actions.getUser();
    actions.getSettings();
  };
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/auth/login");
    } else {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isCreateCompanyRoute, isCreateInterviewRoute } = useMemo(() => {
    const isCreateCompanyRoute = pathname.includes("create-company");
    const isCreateInterviewRoute = pathname.includes("interview/create");
    return { isCreateCompanyRoute, isCreateInterviewRoute };
  }, [pathname]);

  const shouldRedirectToCreateInterview = useMemo(
    () =>
      !!user &&
      user?.interviews_count === 0 &&
      !!user.company &&
      !isCreateInterviewRoute,
    [user, isCreateInterviewRoute],
  );
  const shouldRedirectToCreateCompany = useMemo(
    () => !isCreateCompanyRoute && !loading && user && !user.company,
    [isCreateCompanyRoute, loading, user],
  );

  if (!isAuthenticated) {
    return null;
  }

  if (shouldRedirectToCreateCompany) {
    return <Navigate to="/create-company" />;
  }

  if (shouldRedirectToCreateInterview) {
    return <Navigate to="/interview/create" />;
  }

  return (
    <>
      {!user?.id || loading ? (
        <Loader showFullPage />
      ) : (
        <div className={classes.container}>
          {!hideSideBar && (
            <div className={classes.leftSection}>
              <SideNavigation sidebarElements={sidebarMenu} />
            </div>
          )}

          <div className={classes.rightSection}>
            <div
              className={cn(classes.wrapper, {
                [classes.noPadding]: hideSideBar,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivateLayout;
