import React, { useEffect } from "react";
import Loader from "src/components/Loader";
import { AddPayment } from "src/modules/AddPayment";
import { createStripeIntent } from "src/store/actions/settings";
import { useAppSelector, useStoreActions } from "src/store/hooks";

const AddPaymentPage: React.FC = () => {
  const actions = useStoreActions({ createStripeIntent });
  const loading = useAppSelector((state) => state.setting.stripeLoader);
  const clientSecret = useAppSelector((state) => state.setting.client_secret);

  useEffect(() => {
    actions.createStripeIntent({ data: null });
  }, []);

  if (loading || !clientSecret) {
    return <Loader />;
  }
  return <AddPayment />;
};

export default AddPaymentPage;
