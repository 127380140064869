import { IIntegration } from "src/modules/dashboard/Integration/interface/integration.type";
import { Api } from "./Api";

export class IntegrationService extends Api<IIntegration> {
  constructor() {
    super("/greenhouse");
  }

  async connectGreenhouse(payload: IIntegration, type: "connect" | "sync") {
    return this.post(payload && type === "connect" ? payload : {}, type);
  }

  async disconnectGreenhouse() {
    return this.delete("disconnect");
  }
}
