import { createAsyncThunk } from "@reduxjs/toolkit";
import { SettingsService } from "src/api/SettingsService";
import { notify } from "src/components/Toastify/Toastify";
import {
  IChatFormProps,
  IPlans,
} from "src/modules/dashboard/Settings/interface/settings.type";
import { IResponse } from "src/utils/common.type";
const settingService = new SettingsService();

interface IPayload<T> {
  data: T;
  cb?: () => void;
}

export const getPlans = createAsyncThunk("settings/plans", async () => {
  try {
    const resp = await settingService.getPlans();
    return resp.data?.data || [];
  } catch (err) {
    const { error } = err as IResponse<IPlans>;

    throw error;
  }
});

export const getHistory = createAsyncThunk("settings/history", async () => {
  try {
    const resp = await settingService.getHistory();
    return { data: resp.data.data, meta: resp.data.meta };
  } catch (err) {
    const { error } = err as IResponse<IPlans>;

    throw error;
  }
});

export const subscribePlan = createAsyncThunk(
  "settings/subscribe-plan",
  async (payload: IPayload<{ planId: number }>) => {
    const { data, cb } = payload;
    try {
      const resp = await settingService.subscribePlan(data.planId);
      if (cb) {
        cb();
      }
      if (resp.data?.message) {
        notify({
          message: resp.data?.message || "Subscribed Successfully",
          autoCloseTime: 2000,
          position: "top-right",
          type: "success",
        });
      }
      return resp.data;
    } catch (err) {
      const { error } = err as IResponse<IPlans>;
      if (error) {
        notify({
          message: error?.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      throw error;
    }
  },
);

export const letsChat = createAsyncThunk(
  "settings/letsChat",
  async (payload: IPayload<IChatFormProps>) => {
    const { data, cb } = payload;
    try {
      await settingService.letsChat(data as IChatFormProps);
      if (cb) {
        cb();
      }
      return data;
    } catch (err) {
      const { error } = err as IResponse<IChatFormProps>;
      if (error) {
        notify({
          message: error?.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      if (cb) {
        cb();
      }
      throw error;
    }
  },
);

export const createStripeIntent = createAsyncThunk(
  "settings/stripeIntent",
  async (payload: IPayload<null>) => {
    const { cb } = payload;
    try {
      const resp = await settingService.createStripeIntent();
      if (cb) {
        cb();
      }
      return resp.data?.client_secret[0];
    } catch (err) {
      const { error } = err as IResponse<null>;

      throw error;
    }
  },
);

export const addPaymentMethod = createAsyncThunk(
  "settings/addPaymentMethod",
  async (payload: IPayload<{ stripe_id: string }>) => {
    const { data, cb } = payload;
    try {
      const resp = await settingService.addPaymentMethod(data);
      if (cb) {
        cb();
      }
      return resp.data?.client_secret[0];
    } catch (err) {
      const { error } = err as IResponse<null>;
      if (error) {
        notify({
          message: error?.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      throw error;
    }
  },
);

export const getPaymentMethod = createAsyncThunk(
  "settings/getPaymentMethod",
  async () => {
    try {
      const resp = await settingService.getPaymentMethod();
      return resp.data.data;
    } catch (err) {
      const { error } = err as IResponse<null>;

      throw error;
    }
  },
);
export const removePaymentMethod = createAsyncThunk(
  "settings/removePaymentMethod",
  async (payload: IPayload<number>) => {
    const { data, cb } = payload;
    try {
      await settingService.removePaymentMethod(data);
      if (cb) {
        cb();
      }
      return data;
    } catch (err) {
      const { error } = err as IResponse<null>;
      throw error;
    }
  },
);

export const makeDefault = createAsyncThunk(
  "settings/makeDefault",
  async (payload: IPayload<number>) => {
    const { data, cb } = payload;
    try {
      await settingService.makeDefault(data);
      if (cb) {
        cb();
      }
      return data;
    } catch (err) {
      const { error } = err as IResponse<null>;
      if (error) {
        notify({
          message: error?.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      throw error;
    }
  },
);

export const cancelSubscription = createAsyncThunk(
  "settings/cancelSubscription",
  async (payload: IPayload<null>) => {
    const { cb } = payload;
    try {
      await settingService.cancelSubscription();
      if (cb) {
        cb();
      }
    } catch (err) {
      const { error } = err as IResponse<null>;
      if (error) {
        notify({
          message: error?.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      throw error;
    }
  },
);
