import cn from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { ArrowRightIcon } from "src/assets/icons";
import classes from "./breadCrumb.module.scss";

interface BreadCrumbProps {
  label: string;
  redirectLink: string;
}

const BreadCrumb = ({ data }: { data: BreadCrumbProps[] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={classes.breadCrumbsWrapper}>
      {data.map((item, index) => {
        return (
          <div
            key={`${item.label}-${index}`}
            className={cn(classes.breadCrumb, {
              [classes.active]:
                item.redirectLink === pathname || !item.redirectLink,
            })}
          >
            <span
              onClick={() => {
                navigate(item.redirectLink);
              }}
            >
              {" "}
              {item.label}
            </span>{" "}
            {index < data.length - 1 && <ArrowRightIcon />}
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
