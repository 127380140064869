import { createSlice } from "@reduxjs/toolkit";

import { connectGreenhouse, disconnectGreenhouse } from "../actions";
export interface IAuthSlice {
  greenHouseApi: string;
  message: string;
  loading: boolean;
  error: string;
}

const initialState: IAuthSlice = {
  greenHouseApi: "",
  loading: false,
  error: "",
  message: "",
};

export const authSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //connect/sync to greenhouse
    builder.addCase(connectGreenhouse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(connectGreenhouse.fulfilled, (state, action) => {
      state.loading = false;
      state.greenHouseApi = action.payload.data;
    });
    builder.addCase(connectGreenhouse.rejected, (state, action) => {
      state.loading = false;
      state.greenHouseApi = "";
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(disconnectGreenhouse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disconnectGreenhouse.fulfilled, (state, action) => {
      state.loading = false;
      state.greenHouseApi = action.payload;
    });
    builder.addCase(disconnectGreenhouse.rejected, (state, action) => {
      state.loading = false;
      state.greenHouseApi = "";
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default authSlice.reducer;
