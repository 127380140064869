import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AsyncThunkConfig,
  GetThunkAPI,
  // eslint-disable-next-line import/no-unresolved
} from "@reduxjs/toolkit/dist/createAsyncThunk";
import { PersonService } from "src/api/PersonService";
import {
  ICandidateChart,
  IMutatePerson,
  IPerson,
  IResponsePerson,
} from "src/modules/dashboard/People/interface/person.type";
import { IListParams, IResponse } from "src/utils/common.type";
import { RootState } from "..";
import { updateGroupLists } from "../reducers/group";
import { getGroupList } from "./group";
const personService = new PersonService();

interface IPayload<T> {
  data: T;
  cb?: (response: IResponse<T>) => void;
}
export const addPerson = createAsyncThunk(
  "person/add",
  async (
    payload: Omit<IPayload<IMutatePerson>, "cb"> & {
      cb: (res: IResponsePerson) => void;
    },
  ) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.addPerson(data);
      if (cb) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IPerson>;

      throw error;
    }
  },
);

export const editPerson = createAsyncThunk(
  "person/edit",
  async (
    payload: Omit<IPayload<IMutatePerson>, "cb"> & {
      cb: (res: IResponsePerson) => void;
    },
    { dispatch },
  ) => {
    const { data, cb } = payload;
    try {
      if (!data.id) {
        return { data };
      }
      const resp = await personService.editPerson(data?.id, data);

      dispatch(getGroupList({}));
      if (cb) {
        cb(resp.data);
      }
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IPerson>;

      throw error;
    }
  },
);

export const getPersonsList = createAsyncThunk(
  "persons/get",
  async (payload?: IListParams) => {
    try {
      const resp = await getPersons(payload);
      return { data: resp.data?.data, metadata: resp.data?.meta };
    } catch (err) {
      const { error } = err as IResponse<IPerson>;

      throw error;
    }
  },
);

export const getPerson = createAsyncThunk(
  "person/get",
  async (payload: IPayload<number>) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.getPerson(data);
      if (cb && resp) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IPerson>;
      if (cb) cb({});

      throw error;
    }
  },
);

export const deletePerson = createAsyncThunk(
  "person/delete",
  async (
    payload: IPayload<number>,
    { getState, dispatch }: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const { data, cb } = payload;
    try {
      await personService.deletePerson(data);
      if (cb) cb({ data });
      const { personList, meta: personMeta } = (getState() as RootState).person;
      const { groupList } = (getState() as RootState).group;
      if ((personList || []).length === 1 && personMeta.current_page !== 1) {
        dispatch(getPersonsList({ page: personMeta.current_page - 1 || 1 }));
      }
      const personGroupsIds = personList
        ?.find((person) => person.id === data)
        ?.groups?.map((group) => group.id);

      const formattedGroupList = groupList?.map((group) => {
        if (personGroupsIds?.includes(group.id)) {
          return {
            ...group,
            participants_count: group?.participants_count
              ? group.participants_count - 1
              : group.participants_count,
          };
        } else {
          return group;
        }
      });
      dispatch(updateGroupLists(formattedGroupList));
      return data;
    } catch (err) {
      const { error } = err as IResponse<IPerson>;
      throw error;
    }
  },
);

export const getInterviewParticipant = createAsyncThunk(
  "person/getParticipant",
  async (payload: {
    interviewId: number;
    participantId: number;
    cb?: () => void;
  }) => {
    const { interviewId, participantId, cb } = payload;
    try {
      const resp = await personService.getInterviewParticipant(
        interviewId,
        participantId,
      );
      const { chart } = resp.data;
      const chartValues = (chart?.datasets || []).map(
        (item: { label: string; data: number[] }) => item.data,
      );
      const [confidence, engagement, relevance] = chartValues;

      const formattedChart: ICandidateChart = {
        confidence,
        engagement,
        relevance,
        xLabels: chart?.labels || [],
      };
      if (cb && resp) cb();
      return { questions: [...(resp.data?.data || [])], chart: formattedChart };
    } catch (err) {
      const { error } = err as IResponse<IPerson>;
      if (cb) cb();
      throw error;
    }
  },
);

// only to search person. for dropdown selection
// do not need to store in redux
export const getPersons = async (payload?: {
  page?: number;
  search?: string;
}) => {
  return personService.getPersonsList(payload);
};
